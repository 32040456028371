import request from '@/utils/request'

// 公共接口

// 顶部Logo以及菜单接口
export function getLogoAndMenu(data) {
  return request({
    url: 'Common/logoInform',
    method: 'get',
    params: data
  })
}

// 轮播图列表接口
export function getSlideItem(data) {
  return request({
    url: 'Common/SlideItem',
    method: 'get',
    params: data
  })
}

//提交接口
export function sendMessage(data) {
  return request({
    url: 'Index/sendMessage',
    method: 'post',
    data
  })
}

//底部接口
export function getbottomInform(data) {
  return request({
    url: 'Common/BottomInform',
    method: 'get',
    params: data
  })
}
